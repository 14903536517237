<div class="widgets-cont">
  <!-- begin hack to style fileupload button by making it transparent ref - http://geniuscarrier.com/how-to-style-a-html-file-upload-button-in-pure-css/ -->
  <div class="fileUpload text-center">
    <button id="createstep1f" ion-button small>{{btnText}}</button>
    <input type="file" class="upload" name="multiple" ng2FileSelect [uploader]="uploader" multiple/>

    <div *ngFor="let item of uploader.queue">
      <span>
        <strong>{{ item?.file?.name }}</strong>
      </span>
      <span *ngIf="uploader.isHTML5">{{ item?.file?.size/1024/1024 | number:'.2' }} MB</span>
    </div>
    <div *ngIf="uploader.queue.length">
      Upload progress:
      <div class="progress" style="">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
      </div>
    </div>
  </div>
  <!-- end hack-->
</div>