<ion-header>
  <ion-navbar transparent>
    <button id="createstep0c" ion-button menuToggle>
    <ion-icon name="menu"></ion-icon>
  </button>
    <ion-title class="text-center">{{pageTitle}}</ion-title>
    <ion-buttons end>
      <button class="dp-tablet" id="createstep0a" (click)="startTour(false)" ion-button outline icon-right color="royal">Tour</button>
      <button id="createstep0b" (click)="goToHome()" ion-button outline icon-right color="royal">Home </button>
    </ion-buttons>
  </ion-navbar>
</ion-header>

<ion-content class="dp-page create-page">
  <!-- BEGIN PHONE AND TABLET VIEW -->
  <div class="dp-flex-wrapper dp-phone" [ngClass]="{fullScreen:isFullScreen}">
    <div class="dp-flex-header">
      <!-- begin section 1b - add photos section -->
      <horizpicseltr (thumbnailClickEvt)="onThumbnailClickPhone($event)">
      </horizpicseltr>
      <!-- end add photos section -->
    </div>

    <div class="dp-flex-main">
      <!-- begin app-middle container -->
      <div class="board-cont">
        <h6 class="dp-hint dp-tiny-text text-center positive">Hint: Rotate your phone or tablet twice to automatically resize and rearrange items on your board</h6>
        <board isFullScreen="no" [showBoardTimer]="showBoardTimer" [boardWidth]="mobileBoardWidth" [boardHeight]="mobileBoardHeight"
          [domBoardID]='domBoardID'></board>
      </div>
      <!-- begin app-middle container -->
    </div>

    <div class="dp-flex-footer">
      <!-- begin section 3 - save & exit -->
      <div class="widgets-cont2">
        <div class="dp-text-cont">
          <h6 class="text-center">Step 2 - Save & Exit</h6>
          <!--<h6 class="dp-tiny-text text-center">Scroll & cilck thumbnails to place on board</h6>-->
        </div>
        <div class="text-center">
          <button ion-button small (click)="saveBoardAndExit(domBoardID)">Save & Exit</button>
        </div>
      </div>
      <!-- end section 3 - save & exit -->
    </div>
  </div>
  <!-- END PHONE AND TABLET VIEW -->

  <!-- BEGIN WEB AND TABLET VIEW -->
  <section class="app-container dp-tablet" [ngClass]="{fullScreen:isFullScreen}">

    <!-- begin app-header container -->
    <ion-row class="row header box-shadow dp-phone" *ngIf="showHeader">
      <ion-col class="col text-center">
        <app-header>Test</app-header>
      </ion-col>
    </ion-row>
    <!-- end app-header container -->

    <!-- begin app-middle container -->
    <ion-row class="row middle">
      <ion-col *ngIf="showLeftsidebar" class="col text-center leftsidebar">
        <app-leftsidebar (modeChangeEvt)="onModeChange($event)"></app-leftsidebar>
      </ion-col>
      <ion-col id="createstep1e" *ngIf="showLeftsidebar" class="col text-center leftsideaux dp-tablet">
        <app-leftsideaux class="dp-tablet" (thumbnailClickEvt)="onThumbnailClick($event)"></app-leftsideaux>
      </ion-col>
      <ion-col class="col text-center middlemaincontent">
        <app-middle></app-middle>
      </ion-col>
    </ion-row>
    <!-- begin app-middle container -->

    <!-- begin app-footer container -->
    <ion-row class="row footer" *ngIf="showFooter">
      <ion-col class="col text-center">
        <button ion-button class="button button-positive">Icon</button> Title
      </ion-col>
      <ion-col class="col text-center">
        <button ion-button class="button button-positive">Icon</button>
        <app-footer></app-footer>
      </ion-col>
    </ion-row>
    <!-- begin app-footer container -->
  </section>
  <!-- END WEB AND TABLET VIEW -->

</ion-content>