<div class="resource-list-intro text-center">Here are a list of videos we have found to be useful and inspirational. Special each of the original creators for producing
  these amazing videos.</div>

<ion-list *ngFor="let category of organizedResources">
  <h5>{{category.label}}</h5>
  <ion-grid fixed>
    <ion-row align-items-stretch>
      <ion-col col-12 col-sm-6 col-md-2 align-self-stretch align-self-center *ngFor="let itm of category.resources" approxItemHeight="457px">
        <youtubevideolistitem [video]="itm" (click)="loadVideo(itm)"></youtubevideolistitem>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-list>