<ion-header>

  <ion-navbar>
    <ion-title>books</ion-title>
  </ion-navbar>

</ion-header>


<ion-content padding>

</ion-content>