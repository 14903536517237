<div class="form-container">
  <!--<img id="lock" alt="Big Dream Board Logo" [src]="logoIconOnlySrc">-->
  <img *ngIf="shouldShowLogo" alt="Big Dream Board Logo" [src]="logoSrc">
  <!--<img *ngIf="shouldShowIcon" id="lock" src="assets/img/lock.svg" id="lock">-->
  <ion-icon *ngIf="shouldShowIcon" class="intro-icon dp-theme-primary" name="checkmark-circle-outline"></ion-icon>
  <a href="javascript:void(0)" (click)="goToSignup()" class="alc ">No account?
    <strong>Create one here</strong>
  </a>
  <!--<span class="error" *ngIf="loginerror">{{ loginerror }}</span>-->
  <button type="button" (click)="login('email')" id="email">Login With Email</button>
  <div *ngIf="loginMode == 'email' ">
    <form [formGroup]="loginForm" (submit)="loginEmailForm()" novalidate class="form-horizontal">
      <!-- email -->
      <ion-item>
        <ion-label stacked>{{config.loginfrmEmailLbl}}</ion-label>
        <ion-input #email formControlName="email" type="email" placeholder="Your email address" [class.invalid]="!loginForm.controls.email.valid && (emailChanged || submitAttempt)"></ion-input>
      </ion-item>
      <ion-item class="error-message" *ngIf="!loginForm.controls.email.valid  && (emailChanged || submitAttempt)">
        <p>{{config.loginfrmEmailErr}}</p>
      </ion-item>
      <!-- password -->
      <ion-item>
        <ion-label stacked>{{config.loginfrmPwdLbl}}</ion-label>
        <ion-input #password formControlName="password" type="password" (change)="elementChanged(password)" placeholder="Your password"
          [class.invalid]="!loginForm.controls.password.valid && (passwordChanged || submitAttempt)"></ion-input>
      </ion-item>
      <ion-item class="error-message" *ngIf="!loginForm.controls.password.valid  && (passwordChanged || submitAttempt)">
        <p>{{config.loginfrmPwdErr}}</p>
      </ion-item>
      <!-- buttons -->
      <p class="text-center dp-button-half">
        <button ion-button [disabled]="!loginForm.valid" type="submit">{{config.loginBtn}}</button>
        <button ion-button type="button" outline (click)="goToResetPassword()">{{config.loginBtnForgot}} </button>
        <button ion-button type="button" outline (click)="cancelEmailLogin()">{{config.loginBtnCancel}} </button>
      </p>
      <a href="javascript:void(0)" (click)="goToSignup()" class="alc ">No account?
        <strong>Create one here</strong>
      </a>
    </form>
  </div>
  <div>
    <button type="button" (click)="login('gg')" id="google">Login With Google</button>
    <button type="button" (click)="login('fb')" id="fb">Login With Facebook</button>
  </div>
  <!-- todo <button (click)="login('tw')" id="tw">Login With Twitter</button>-->
  <!--<a href="javascript:void(0)" (click)="goToSignup()" class="alc ">No account? <strong>Create one here</strong></a>-->
</div>
