<ion-content class="cards-bg">
  <ion-card>
    <img [src]="settingsForm?.get('displayOptions.imagePath')?.value" />
    <span class="itemText" [style.color]="selectedColor" [style.font-size]="settingsForm?.get('displayOptions.fontSize')?.value +'px'">{{itemText.value}}</span>
    <ion-card-content>
      <!--<p>Form value: {{ settingsForm.value | json}}</p>-->
      <form [formGroup]="settingsForm" (ngSubmit)="saveBtnH()">
        <!--<ion-card-title>Display Options</ion-card-title>-->
        <ion-list formGroupName="displayOptions">
          <!--<ion-row>
            <ion-col>
              <ion-item>
                <ion-label>Image :</ion-label>-->
          <!--<ion-input type="text" formControlName="imagePath"></ion-input>-->
          <!-- </ion-item>
            </ion-col>
            <ion-col col-auto>
              <fileuploadbtn [thumbs]="thumbs" btnText="Upload / Replace Image"></fileuploadbtn>
            </ion-col>
          </ion-row>-->
          <ion-item>
            <ion-label>Display Text :</ion-label>
            <ion-input #itemText type="text" formControlName="itemText"></ion-input>
          </ion-item>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label>Font Size: :&nbsp;</ion-label>
                <ion-select #itemFontSize type="number" formControlName="fontSize">
                  <ion-option value=""></ion-option>
                  <ion-option *ngFor="let item of fontSizes" [value]="item">{{item}}</ion-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-col col-auto>
                  <ion-item>
                    <ion-label class="dp-text-shadow-shallow" [style.color]="selectedColor" [style.background-color]="tempBgColor">Text Color :</ion-label>
                  </ion-item>
                </ion-col>
                <ion-col (click)="colorChoiceH(item)" *ngFor="let item of textColors">
                  <span class="colorChoice box-shadow" [style.background-color]="item"></span>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-list>
        <br>
        <!--<ion-card-title>Slide Show Options</ion-card-title>-->
        <!--<ion-list formGroupName="slideShowOptions">
          <ion-grid>
            <ion-row>-->
        <!--<ion-col>
                <ion-item>
                  <ion-label>Item Order :&nbsp;</ion-label>
                  <ion-select type="number" formControlName="itemOrder">
                    <ion-option value=""></ion-option>
                    <ion-option *ngFor="let item of itemDisplayOrder" [value]="item">{{item}}</ion-option>
                  </ion-select>
                </ion-item>
              </ion-col>-->
        <!-- <ion-col>
                <ion-item>
                  <ion-label>In Slide Show Display This Item For X (seconds) :&nbsp;</ion-label>
                  <ion-select type="number" formControlName="showForSeconds">
                    <ion-option *ngFor="let item of itemDisplaySeconds" [value]="item">{{item}}</ion-option>
                  </ion-select>
                </ion-item>
              </ion-col>-->
        <!--<ion-col>
                <ion-item></ion-item>
              </ion-col>-->
        <!-- <ion-col>
                <ion-item>
                  <ion-label>Music for this item :&nbsp;</ion-label>
                  <ion-select type="number" formControlName="musicFilePath">
                    <ion-option value=""></ion-option>
                    <ion-option *ngFor="let item of musicFiles" [value]="item">{{item.label}}</ion-option>
                  </ion-select>
                </ion-item>
              </ion-col>-->
        <!-- </ion-row>
          </ion-grid>
        </ion-list>
        <br>-->
        <!--<ion-card-title>Goal Options :</ion-card-title>
        <ion-list formGroupName="goalOptions">
          <ion-item>
            <ion-label>Category :</ion-label>
            <ion-input type="text" formControlName="category"></ion-input>
          </ion-item>

          <ion-item>
            <ion-label>Description :</ion-label>
            <ion-textarea formControlName="description"></ion-textarea>
          </ion-item>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label>Date Added :</ion-label>
                <ion-datetime displayFormat="HH:mm" pickerFormat="HH mm" [min]="minDatePickerDate" [max]="maxDatePickerDate" formControlName="dateAdded"></ion-datetime>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item>
                <ion-label>Date Achieved :</ion-label>
                <ion-input type="text" formControlName="dateAchieved"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-item>
            <ion-label>Tags :</ion-label>
            <ion-input type="text" formControlName="goalTags"></ion-input>
          </ion-item>
        </ion-list>-->
        <p class="text-center dp-formgroup">
          <button ion-button icon-left type="button" type="button" color="dark" (click)="cancelBtnClickH()">
            <ion-icon name="close"></ion-icon> Cancel</button>
          <button ion-button icon-left type="submit" type="submit" color="primary">
            <ion-icon name="checkmark"></ion-icon> Save</button>
        </p>
      </form>
    </ion-card-content>
  </ion-card>
</ion-content>
