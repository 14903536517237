<ion-header>
  <ion-navbar>
    <!--<button ion-button menuToggle>
    <ion-icon name="menu"></ion-icon>
  </button>-->
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
    <ion-buttons end>
      <button (click)="goToLogin()" ion-button outline icon-right color="dark">
        Login
        <!--<ion-icon name="send"></ion-icon>-->
      </button>
    </ion-buttons>
  </ion-navbar>
</ion-header>

<ion-content>

  <!-- begin intro -->
  <div class="dp-section dp-section0">
    <!--<button (click)="scrollToElement('signup')" ion-button icon-right color="light">
        Create Your Board Now
      </button>-->
    <button (click)="goToLogin()" ion-button icon-right color="light">
        Create Your Board Now
      </button>
    <!--<p class="dp-subtitle dp-text-shadow-deep">Welcome</p>-->
    <p class="dp-subtitle dp-text-shadow-deep">The Vision Board That Goes Where You Go</p>
    <div class="dp-subsubtitle">Powerful . Beautiful . Effective</div>
    <ion-row class="dp-images-cont">
      <ion-col><img class="thumbnail" src="assets/img/bundled/pubdomain/vb1000001_abstract.jpg"></ion-col>
      <ion-col><img class="thumbnail" src="assets/img/bundled/pubdomain/vb1000001_love.jpg"></ion-col>
      <ion-col><img class="thumbnail" src="assets/img/bundled/pubdomain/vb1000002_abstract.jpg"></ion-col>
    </ion-row>

  </div>
  <!-- end intro -->

  <div class="dp-title-block">
    <!--<h2 class="dp-title-large">Big Vision Board</h2>-->
    <h3 class="dp-subtitle">Belive . Act . Achieve</h3>
  </div>

  <!-- begin benefits -->
  <!-- begin features -->
  <div class="dp-section dp-section1">
    <ion-row wrap>
      <ion-col class="dp-circle-items" *ngFor="let item of introBenefits">
        <div class="circle-item">
          <img alt="Circle user" class="circle-icon trigger" [src]="item.img">
          <h4 class="circle-title">{{item.title}}</h4>
          <p class="circle-content">{{item.description}}</p>
        </div>
      </ion-col>
    </ion-row>
    <!--<div class="text-center">
      <button icon-right ion-button (click)="createAccount()">Create Your Free Account <ion-icon name="md-checkmark"></ion-icon> </button>
    </div>-->
  </div>

  <!-- end features -->
  <!--<div class="text-center">
    <button (click)="scrollToElement('signup')" ion-button outline icon-right color="royal">
        Sign Up
      </button>
  </div>-->


  <!-- begin photos -->
  <div class="dp-section dp-section2">
    <ion-row wrap class="card-background-page">
      <h3>Lots of Amazing Inspirational Photos</h3>
      <ion-col class="dp-circle-items" *ngFor="let item of cardbgs">

        <ion-card class="dp-stickynotefullscr">
          <img [src]="item.img" />
          <div class="card-title dp-text-shadow-deep">{{item.title}}</div>
          <div class="card-subtitle dp-text-shadow-deep">{{item.subtitle}}</div>
        </ion-card>

      </ion-col>
    </ion-row>
  </div>
  <!-- end photos -->

  <!-- end benefits -->

  <!-- begin pricing -->
  <!--<div id="signup">
    <br>
    <h2>commitment is the key to sucess</h2>
    <h6 class="text-center">Get started today</h6>
    <h3 class="text-center dp-guarantee">100% Risk-Free 60-Day Money Back Guarantee</h3>
    <ion-row class="dp-images-cont">
      <ion-col></ion-col>
      <ion-col width-75 class="text-center">
        <img class=" dp-guarantee-thumbnail" src="assets/img/vision_board_moneyback.png ">
        <img class="dp-guarantee-thumbnail" src="assets/img/vision_board_satisfaction.jpg ">
      </ion-col>
      <ion-col></ion-col>
    </ion-row>
    <pricingtable></pricingtable>
  </div>-->
  <!-- end pricing -->
</ion-content>