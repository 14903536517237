<ion-header>
  <ion-navbar transparent align-title="center">
    <button ion-button menuToggle>
      <ion-icon name="menu"></ion-icon>
    </button>
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
    <ion-buttons end>
      <button type="button" class="dp-tablet" (click)="homeH()" ion-button outline icon-right color="royal">Home</button>
      <!-- <button type="button" class="dp-tablet" (click)="logoutH()" ion-button outline icon-right color="royal">Logout</button>-->
    </ion-buttons>
  </ion-navbar>
</ion-header>


<ion-content padding>
  <div class="form-container">
    <img *ngIf="shouldShowLogo" alt="Big Dream Board Logo" [src]="logoSrc">
    <ion-icon *ngIf="shouldShowIcon" class="intro-icon dp-theme-primary" name="checkmark-circle-outline"></ion-icon>


    <div [hidden]="submitted">
      <h1 class="text-center">Contact Us</h1>
      <p>Got a question or suggestion? We'd love to hear from you. Send us a message and we'll respond as soon as possible</p>
      <!--<form #contactForm="ngForm" [formGroup]="ngForm" (submit)="captchaRef.execute()" novalidate class="form-horizontal">-->
      <form #contactForm="ngForm" [formGroup]="ngForm" (submit)="onSubmitNoRecapt(contactForm)" novalidate class="form-horizontal">
        <!-- contactName -->
        <ion-item>
          <ion-label stacked>{{config.contactfrmContactNameLbl}}</ion-label>
          <ion-input #contactName formControlName="contactName" type="text" placeholder="Your full name" [class.invalid]="!ngForm.controls.contactName.valid && (emailChanged || submitAttempt)"></ion-input>
        </ion-item>
        <ion-item class="error-message" *ngIf="!ngForm.controls.contactName.valid  && (emailChanged || submitAttempt)">
          <p>{{config.contactfrmContactNameErr}}</p>
        </ion-item>
        <!-- contactEmail -->
        <ion-item>
          <ion-label stacked>{{config.contactfrmEmailLbl}}</ion-label>
          <ion-input #email formControlName="contactEmail" type="contactEmail" placeholder="Your email address" [class.invalid]="!ngForm.controls.contactEmail.valid && (emailChanged || submitAttempt)"></ion-input>
        </ion-item>
        <ion-item class="error-message" *ngIf="!ngForm.controls.contactEmail.valid  && (emailChanged || submitAttempt)">
          <p>{{config.contactfrmEmailErr}}</p>
        </ion-item>

        <!-- contactSubject -->
        <ion-item>
          <ion-label stacked>{{config.contactfrmSubjectLbl}} :&nbsp;</ion-label>
          <ion-select #contactSubject type="text" formControlName="contactSubject">
            <ion-option value="">[ Select topic ]</ion-option>
            <ion-option *ngFor="let item of subjects" [value]="item.lbl">{{item.lbl}}</ion-option>
          </ion-select>
        </ion-item>

        <!-- message -->
        <ion-item>
          <ion-label stacked>{{config.contactfrmContactMessageLbl}}</ion-label>
          <ion-textarea #contactMessage (keyup)="countChars($event)" maxlength="250" rows="6" formControlName="contactMessage" type="text"
            placeholder="Your message" [class.invalid]="!ngForm.controls.contactMessage.valid && (emailChanged || submitAttempt)"></ion-textarea>
        </ion-item>
        <span class="char-count text-center">{{ charsLeft }} characters left</span>
        <ion-item class="error-message" *ngIf="!ngForm.controls.contactMessage.valid  && (emailChanged || submitAttempt)">
          <p>{{config.contactfrmContactMessageErr}}</p>
        </ion-item>
        <!-- password -->

        <!-- not working yet error msg unable to contact recaptcha-
        <re-captcha #captchaRef="reCaptcha" name="captcha" [siteKey]="recaptchKey" size="invisible" (resolved)="$event && onSubmit($event,contactForm)"></re-captcha>
        -->
        <!-- buttons -->
        <p class="text-center dp-button-half">
          <button ion-button [disabled]="!ngForm.valid" type="submit">{{config.contactBtnLbl}}</button>
          <button ion-button type="button" outline (click)="cancel()">{{config.cancelBtnLbl}} </button>
        </p>
      </form>
    </div>
    <div [hidden]="!submitted">
      <h1 class="text-center">Thanks.</h1>
      <h3>We will get back to you as soon as we can. Thanks for heiping us improve Big Dream Board for folks like you.</h3>
    </div>
    <!--<div class="text-center">
      <a href='/#/homeclean'>Home</a>
      <a href='/#/help'>help</a>
      <a href='/#/login'>Logout</a>
    </div>-->
  </div>
</ion-content>
