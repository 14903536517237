<ion-menu [content]="content">
  <ion-header>
    <ion-toolbar>
      <ion-title>Menu</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-list>
      <button menuClose ion-item *ngFor="let p of pages" (click)="openPage(p)">
        {{p.title}}
        <ion-icon item-start [name]="p.icon" [color]="isActive(p)"></ion-icon>
      </button>
    </ion-list>
    <!-- Begin resources menu -->
    <!--
    <ion-list>
      <ion-list-header>
        Resources
      </ion-list-header>
      <button ion-item menuClose (click)="openPage('ResourcesPage','videos')">
          <ion-icon item-start name="logo-youtube"></ion-icon>
          Videos
        </button>
      <button ion-item menuClose (click)="openPage('ResourcesPage','books')">
          <ion-icon item-start name="list"></ion-icon>
          Books
        </button>
    </ion-list>
  -->
    <ion-list>
      <ion-list-header>
        Help
      </ion-list-header>
      <button ion-item menuClose (click)="openIntro()">
        <ion-icon item-start name="help"></ion-icon>
        Quick Tour
      </button>
      <button ion-item menuClose (click)="openHelp()">
        <ion-icon item-start name="help-circle"></ion-icon>
        User Guide
      </button>
      <button ion-item menuClose (click)="contactUsH()">
        <ion-icon item-start name="help-circle"></ion-icon>
        Contact Us
      </button>

    </ion-list>

  </ion-content>

</ion-menu>

<!-- Disable swipe-to-go-back because it's poor UX to combine STGB with side menus -->
<ion-nav [root]="rootPage" #content swipeBackEnabled="false"></ion-nav>
