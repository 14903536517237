<div>
  <button ion-button icon-button (click)="showQOTD()">Random Quote</button>
  <ion-searchbar [(ngModel)]="searchTerm" [formControl]="searchControl" (ionInput)="onSearchInput()"></ion-searchbar>

  <div *ngIf="searching || loading" class="spinner-container">
    <ion-spinner></ion-spinner>
  </div>
  <ion-scroll scrollX="true" scrollY="false" class="quotes-cont">
    <ion-list>
      <ion-item *ngFor="let item of items">
        <p class="quoteText text-wrap">{{item.quote}}</p>
        <div class="quoteAuthor text-wrap">{{item.author}}</div>
      </ion-item>
    </ion-list>
  </ion-scroll>

</div>