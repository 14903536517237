
<ion-header>
  <ion-toolbar>
    <ion-title class="text-center">Video</ion-title>
    <ion-buttons start>
      <button ion-button (click)="dismiss()">
        <!--<span ion-text color="primary" showWhen="ios">Cancel</span>
        <ion-icon name="md-close" showWhen="android, windows"></ion-icon>-->
        <ion-icon name="md-close" ></ion-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content><youtubevideoplayer [video]="modalProps" (onCanceled)="dismiss($event)" ></youtubevideoplayer></ion-content>
