<ion-header>
  <ion-navbar transparent align-title="center">
    <button id="introstep1c" ion-button menuToggle>
      <ion-icon name="menu"></ion-icon>
    </button>
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
    <ion-buttons end>
      <button type="button" class="dp-tablet" (click)="logoutH()" ion-button outline icon-right color="royal">Logout</button>
    </ion-buttons>
  </ion-navbar>
</ion-header>


<ion-content padding>
  <profilecomponent></profilecomponent>
</ion-content>
