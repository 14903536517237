<div ng2FileDrop #myFabricCanvasRef class="board" [ngClass]="{'dp-dragover-active':clsDragOverActive}" (dragend)="false"
	(drop)="handleDrop($event)" (dragenter)="dragenter()" (dragover)="dragover()" (dragleave)="dragleave()" (fileOver)="fileOverBase($event)"
	[uploader]="uploader">

	<!-- board title -->
	<div class="board-title board-title-top text-center" (click)="boardTitleClickH();">{{properties.title}} &nbsp;<span class="small-text">( Click to edit title)</span></div>

	<!-- begin actual board -->
	<canvas id="createstep1d" #fabricCanvasRef id="domBoardID" class="board-canvas"></canvas>
	<!-- end actual board -->
	<div>
		<h6 *ngIf="!showBoardTimer" class="dp-hint dp-tiny-text text-center">Hint: Cilck & drag items on board to reposition. Drag edges to resize & rotate</h6>
		<h6 *ngIf="!showBoardTimer" class="dp-hint dp-tiny-text text-center dp-phone ">Hint: For best results create your board on a larger screen and view on your phone</h6>
	</div>

	<div class="board-title board-title-bottom text-center" (click)="boardTitleClickH();">{{properties.title}}</div>

	<!-- board timer -->
	<div *ngIf="showBoardTimer" class="board-timer">
		{{boardTimerStr}}
	</div>
	<!-- begin canvas action buttons -->
	<p *ngIf="boardTB.show" class="dp-canvas-toolbar" [style.left.px]="boardTB.left" [style.top.px]="boardTB.top">
		<!-- delete image button -->
		<button ion-button (click)="deleteCanvasItemH();">Delete</button>

		<!-- add text and group with image button -->
		<span>
			<button  ion-button *ngIf="hideTBTextarea" (click)="tbEditTextBtnH();" >Edit Text</button>
			<button  ion-button item-right icon-left *ngIf="hideTBTextarea" (click)="incrFontSizeToolBtnH();" color="dark">
				<ion-icon name="arrow-round-up"></ion-icon>
				Font Size
				</button>
			<button  ion-button *ngIf="!hideTBTextarea" (click)="saveTextToolBtnH();" >Save Text</button>
			<button  ion-button item-right icon-left  *ngIf="hideTBTextarea" (click)="decrFontSizeToolBtnH();" color="dark">
				<ion-icon name="arrow-round-down"></ion-icon>
				Font Size
				</button>
			<button  ion-button (click)="hideToolBtnsH();" positive>
			<!--<ion-icon name='md-checkbox-outline' is-active="false"></ion-icon>-->
			Hide Buttons
			</button>
			<div>
				<textarea *ngIf="!hideTBTextarea" type="text" placeholder="Enter text here" (change)="tileTextChangeH()" [(ngModel)]="tileText"></textarea>
			</div>		
        </span>
	</p>
	<!-- end canvas action buttons -->
	</div>