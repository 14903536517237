<div>
  <ion-card *ngIf="payload && payload.yt" class="resource-card">

    <!--<img src="assets/img/nin-live.png" />-->
    <div *ngIf="payload.yt.snippet.thumbnails.default.url">
      <img [src]="payload.yt.snippet.thumbnails.default.url">
    </div>

    <ion-card-content>
      <ion-card-title class="video-title">
        {{payload.yt.snippet.title}}
      </ion-card-title>
      <!--<p>
        The most popular industrial group ever, and largely responsible for bringing the music to a mass audience.
      </p>-->
    </ion-card-content>

    <!--<ion-row no-padding>
      <ion-col>
        <button ion-button clear small color="danger" icon-start>
          <ion-icon name='star'></ion-icon>
          Favorite
        </button>
      </ion-col>
      <ion-col text-center>
        <button ion-button clear small color="danger" icon-start>
          <ion-icon name='musical-notes'></ion-icon>
          Watch
        </button>
      </ion-col>
      <ion-col text-right>
        <button ion-button clear small color="danger" icon-start>
          <ion-icon name='share-alt'></ion-icon>
          Share
        </button>
      </ion-col>
    </ion-row>-->

  </ion-card>
</div>