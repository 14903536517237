<div *ngIf="pmt.membership | async as membership">
  <button class="signupbtn blue" *ngIf="membership?.planID != planID || (membership?.planID == planID && membership?.status == 'unsubscribed')"
    (click)="getSubBtnH()">
    {{ buttonText }}
  </button>
  <!--{{planID}}
  <br>{{membership | json}}
  <br>{{plan | json}}-->
  <div *ngIf="membership?.planID == planID && plan?.status != 'unsubscribed' && membership?.status != 'unsubscribed' " (click)="ownedPlanClickH()">
    <button color="dark" ion-button round icon-start class="signupbtn dp-large-btn">
      <ion-icon name="checkmark-circle"></ion-icon>
      You own this
    </button>
  </div>
</div>
