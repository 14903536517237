<div>
  <ion-grid>
    <ion-row>
      <ion-col col-1></ion-col>
      <ion-col>
        <ion-card>
          <ion-card-content>
            <form [formGroup]="profileForm" (ngSubmit)="profileChangesBtnH()">
              <h3 class="dp-card-title ">Account Info</h3>
              <ion-item-group formGroupName="accountOptions">
                <ion-item>
                  <ion-label>*Email :</ion-label>
                  <ion-input #itemText type="text" formControlName="accountEmail"></ion-input>
                </ion-item>
                <!--<ion-item>
                  <ion-label>Display Name :</ion-label>
                  <ion-input #itemText type="text" formControlName="displayName"></ion-input>
                </ion-item>-->
                <ion-item>
                  <ion-row>
                    <ion-col>
                      <span>Password: &nbsp;</span>
                      <button small (click)="changePwdH()" ion-button icon-left type="button" color="light">
                        <ion-icon name="shuffle"></ion-icon> Change</button>
                    </ion-col>
                  </ion-row>
                </ion-item>
              </ion-item-group>
              <!--<p class="text-center dp-formgroup">
                    <button ion-button icon-left type="button" color="light">
                      <ion-icon name="checkmark"></ion-icon> Save Changes</button>
                  </p>-->
            </form>
          </ion-card-content>
        </ion-card>
        <br>
        <br>
        <ion-card>

          <ion-card-content>
            <h3 class="dp-card-title ">Membership Info</h3>
            <ion-item-group>
              <ion-item>
                <div>
                  <br>
                  <h2>Current Subscription</h2>
                  <p *ngIf="plan?.status != 'unsubscribed'">{{plan?.description}}. Amount {{plan?.amount|stripe }}</p>
                  <p *ngIf="plan?.status == 'unsubscribed'">{{plan?.description}}.
                    <span class="error">Status: Unsubscribed</span>
                  </p>
                </div>
              </ion-item>
            </ion-item-group>
            <br>
            <!--test {{plan|json}}-->
            <p class="text-left dp-formgroup">
              <!--<button (click)="changePaymentDetails()" ion-button icon-left type="button" color="light">
                <ion-icon name="shuffle"></ion-icon> Change Payment Method</button>-->

              <button small *ngIf="plan?.key == 'trial' || plan?.key == 'unsubscribed'  || plan?.status == 'unsubscribed' " (click)="changeSubscriptionPlan()"
                ion-button icon-left type="button" color="light">
                <ion-icon name="swap"></ion-icon> Change Subscription Plan</button>
              <button small *ngIf="plan?.key != 'trial' && plan?.status != 'unsubscribed' " (click)="confirmCancelSubscription()" ion-button
                icon-left type="button" color="light">
                <ion-icon name="close"></ion-icon> Cancel Subscription</button>
            </p>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col col-1></ion-col>
    </ion-row>
  </ion-grid>

</div>
