<!--
  <ion-card *ngIf="payload && payload.book" class="resource-card">
    <div *ngIf="payload.book.imageLinks && payload.book.imageLinks.smallThumbnail">
      <img [src]="payload.book.imageLinks.smallThumbnail">
    </div>
    <ion-card-content>
      <ion-card-title class="video-title">
        {{payload.book.title}}
      </ion-card-title>
      <p>
        {{payload.book.authors[0]}}
      </p>
      <p class="book-description">
        {{payload.book.description}}
      </p>
    </ion-card-content>
  </ion-card>
-->

<!--<ion-item *ngIf="payload && payload.book" >
    <ion-avatar item-start *ngIf="payload.book.imageLinks && payload.book.imageLinks.smallThumbnail">
      <img [src]="payload.book.imageLinks.smallThumbnail">
    </ion-avatar>
    <h2> {{payload.book.title}}</h2>
    <h3>{{payload.book.authors[0]}}</h3>
    <p>{{payload.book.description}}</p>
  </ion-item>-->

<ion-item *ngIf="payload && payload.book">
  <ion-thumbnail item-start *ngIf="payload.book.imageLinks && payload.book.imageLinks.smallThumbnail">
    <img [src]="payload.book.imageLinks.thumbnail">
  </ion-thumbnail>
  <h2> {{payload.book.title}}</h2>
  <h3>{{payload.book.authors[0]}}</h3>
  <p>{{payload.book.description}}</p>
  <!--<button ion-button clear item-end>View</button>-->
</ion-item>