
<ion-header>
  <ion-toolbar>
    <ion-title>
      Quotes & Affirmations
    </ion-title>
    <ion-buttons start>
      <button ion-button (click)="dismiss()">
        <span ion-text color="primary" showWhen="ios">Cancel</span>
        <ion-icon name="md-close" ></ion-icon>
        <ion-icon name="md-close" showWhen="web"></ion-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
<quotelistcompact></quotelistcompact>
</ion-content>
