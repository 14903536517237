<div class="widgets-cont">
  <ion-grid>
    <ion-row>
      <ion-col col-2></ion-col>
      <ion-col>
        <div class="dp-text-cont">
          <h6 class="text-center dp-section-title">Step 1 - Add Photos</h6>
          <div class="dp-tiny-text text-center">Scroll & cilck thumbnails to place on board</div>
        </div>
      </ion-col>
      <ion-col col-2>
        <!-- begin hack to style fileupload button by making it transparent ref - http://geniuscarrier.com/how-to-style-a-html-file-upload-button-in-pure-css/ -->
        <fileuploadbtn class="dp-tablet" [thumbs]="thumbs" btnText="OR Upload Image"></fileuploadbtn>
        <!-- end hack-->
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-item class="dp-nopadding">
    <ion-scroll class="dp-scroll-horiz" scrollX="true">
      <img *ngFor="let itm of thumbs" (click)="thumbnailClickEvtH(itm)" class="dp-photo {{itm.active ? 'active': 'inactive'}}"
        src={{itm.url}} />
    </ion-scroll>
  </ion-item>


</div>