<ion-header>
  <ion-navbar transparent align-title="center"><button id="introstep1c" ion-button menuToggle><ion-icon name="menu"></ion-icon></button>
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
    <ion-buttons end>
      <button type="button" class="dp-tablet" id="introstep1a" (click)="logoutH()" ion-button outline icon-right color="royal">Logout</button>
    </ion-buttons>
  </ion-navbar>
</ion-header>

<ion-content padding>

  <div padding class="cont-segment-header">
    <ion-segment [(ngModel)]="selectedResourceType">
      <ion-segment-button value="videos">
        Videos
      </ion-segment-button>
      <ion-segment-button value="books">
        Books
      </ion-segment-button>
    </ion-segment>
  </div>

  <div [ngSwitch]="selectedResourceType">

    <div *ngSwitchCase="'videos'" class="resource-section-header">
      <youtubevideolist></youtubevideolist>
    </div>

    <div *ngSwitchCase="'books'">
      <booklist></booklist>
    </div>

  </div>

</ion-content>