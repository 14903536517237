<ion-card n0-padding>
  <!--<ion-card-title class="text-center">Board Preview</ion-card-title>-->
  <div class="cont-board">
    <board-classic #board isFullScreen="no" [boardWidth]="mobileBoardWidth" [boardHeight]="mobileBoardHeight" [domBoardID]='domBoardID'></board-classic>
  </div>
  <ion-card-content no-padding>
    <!--<audio-player-compact>test</audio-player-compact>-->
    <form *ngIf="currentViewMode == this.viewModes.boardSettingsMode" [formGroup]="settingsForm">
      <div class="dp-formgroup">
        <!--<ion-card-title class="text-center">Board Properties</ion-card-title>-->
        <ion-list formGroupName="boardProperties">
          <ion-item>
            <ion-label>Board Title :</ion-label>
            <ion-textarea type="text" formControlName="boardTitle"></ion-textarea>
          </ion-item>

          <ion-item>
            <ion-label>Board Description :</ion-label>
            <ion-textarea type="text" formControlName="boardDescription" rows="4"></ion-textarea>
          </ion-item>
        </ion-list>
        <!-- <ion-card-title class="text-center">Slide Show Options</ion-card-title>-->
        <ion-list formGroupName="slideShowOptions">
          <!-- DO NOT DELETE
          <ion-item>
            <ion-label>Slide Show Duration for each item :&nbsp;</ion-label>
            <ion-select type="number" formControlName="boardDefaultItemShowForSeconds">
              <ion-option *ngFor="let item of itemDisplaySeconds" [value]="item">{{item}}</ion-option>
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-label>Board Music:&nbsp;</ion-label>
            <ion-select #boardMusicFile type="text" formControlName="boardDefaultMusicFilePath">
              <ion-option value="">No music</ion-option>
              <ion-option *ngFor="let item of musicFiles" [value]="item">{{item.title}}</ion-option>
            </ion-select>
          </ion-item>
          <ion-item *ngIf="settingsForm?.get('slideShowOptions.boardDefaultMusicFilePath')?.value">
            <div> Listen to sample</div>
            <audio-player-compact></audio-player-compact>
          </ion-item>-->
        </ion-list>
        <!-- <br><br>-->

        <div class="text-center"> Select Board Music</div>
        <div class="dp-music-cont">
          <audio-player-listpicker (onTrackSelected)="onTrackSelectedH($event)" [currentTrack]="selectedMusicTrack"></audio-player-listpicker>
        </div>

      </div>
      <!-- Save Button see form ngSubmit for handler -->
      <p class="text-center dp-formgroup">
        <button ion-button icon-left type="button" color="dark" (click)="cancelBtnClickH()"><ion-icon name="close" ></ion-icon> Cancel</button>
        <button ion-button icon-left type="submit" color="primary" (click)="saveBtnH()"> <ion-icon name="checkmark" ></ion-icon> Save</button>
      </p>
      <br>
      <div *ngIf="currentViewMode == this.viewModes.boardItemSettingsMode">
        <ion-card-title class="text-center">Edit Board Items</ion-card-title>
        <ion-list>
          <ion-item *ngFor="let itm of boardDreamItems">
            <ion-thumbnail item-left>
              <img [src]="itm.src">
            </ion-thumbnail>
            <h2><b>Text :</b>{{itm.text}}</h2>
            <p><b>IDX:</b>{{itm.idx}}</p>
            <button ion-button clear item-right (click)="editBoardItemH(itm)">Edit</button>
          </ion-item>
        </ion-list>
      </div>
    </form>
  </ion-card-content>
</ion-card>