
<ion-header>
  <ion-toolbar>
    <ion-title class="text-center">Please Sign In</ion-title>
    <ion-buttons start>
      <button ion-button (click)="dismiss()">
        <!--<span ion-text color="primary" showWhen="ios">Cancel</span>
        <ion-icon name="md-close" showWhen="android, windows, web"></ion-icon>-->
        <ion-icon name="md-close" ></ion-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <loginsocial [showIcon]="true" [showLogo]="false"></loginsocial>
</ion-content>
