<ion-header>
  <ion-navbar transparent>
    <button ion-button menuToggle>
    <ion-icon name="menu"></ion-icon>
  </button>
    <ion-title class="text-center">{{appTitle}}</ion-title>
  </ion-navbar>
</ion-header>

<ion-content padding class="dp-page create-page">
  <pricingtable></pricingtable>
</ion-content>