<ion-header>
  <ion-navbar>
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
    <!--<ion-buttons end>
			<button (click)="goToWelcome()" ion-button outline icon-right color="primary">
        About
      </button>
		</ion-buttons>-->
  </ion-navbar>
</ion-header>

<ion-content padding class="login-page dp-content">
  <br>
  <br>
  <br>
  <div class="auth-main">
    <div class="auth-block dp-form-wrap">

      <a href="https://bigdreamboard.com/membership-options/" class="auth-link">
        <h6>{{config.loginSignup}}</h6>
      </a>
      <form [formGroup]="loginForm" (submit)="loginUser()" novalidate class="form-horizontal">
        <!-- email -->
        <ion-item>
          <ion-label stacked>{{config.loginfrmEmailLbl}}</ion-label>
          <ion-input #email formControlName="email" type="email" (change)="elementChanged(email)" placeholder="Your email address"
            [class.invalid]="!loginForm.controls.email.valid && (emailChanged || submitAttempt)"></ion-input>
        </ion-item>
        <ion-item class="error-message" *ngIf="!loginForm.controls.email.valid  && (emailChanged || submitAttempt)">
          <p>{{config.loginfrmEmailErr}}</p>
        </ion-item>
        <!-- password -->
        <ion-item>
          <ion-label stacked>{{config.loginfrmPwdLbl}}</ion-label>
          <ion-input #password formControlName="password" type="password" (change)="elementChanged(password)" placeholder="Your password"
            [class.invalid]="!loginForm.controls.password.valid && (passwordChanged || submitAttempt)"></ion-input>
        </ion-item>
        <ion-item class="error-message" *ngIf="!loginForm.controls.password.valid  && (passwordChanged || submitAttempt)">
          <p>{{config.loginfrmPwdErr}}</p>
        </ion-item>
        <!-- buttons -->
        <p class="dp-buttonbar">
          <button ion-button [disabled]="!loginForm.valid" type="submit">{{config.loginBtn}}</button>
          <button ion-button type="button" outline (click)="goToResetPassword()">{{config.loginBtnForgot}} </button>
        </p>
      </form>
    </div>
  </div>
</ion-content>
