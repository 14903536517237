
<ion-header>
  <ion-toolbar>
    <ion-title class="text-center">Board Settings</ion-title>
    <ion-buttons start>
      <button ion-button (click)="dismiss()">
        <!--<span ion-text color="primary" showWhen="ios">Cancel</span>
        <ion-icon name="md-close" showWhen="android, windows"></ion-icon>-->
        <ion-icon name="md-close" ></ion-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content><board-settings [settingsViewMode]="settingsViewMode" [payload]="modalProps" (onCanceled)="dismiss($event)" (onSaved)="saveBtnH($event)"></board-settings></ion-content>
