<!--<div #fabricCanvasContRef [style.width.px]="boardCanvasWidth" [style.height.px]="boardCanvasHeight" class="stretchy-wrapper xxdp-stickynote box-shadow">-->
<div #fabricCanvasContRef class="stretchy-wrapper xxdp-stickynote box-shadow">
    <div>{{tileText}}</div>
    <div ng2FileDrop class="board">
        <canvas #fabricCanvasRef id="domBoardID" class="board-canvas"></canvas>
    </div>

    <!-- begin canvas action buttons -->
    <p *ngIf="boardTB.show" class="dp-canvas-toolbar" [style.left.px]="boardTB.left" [style.top.px]="boardTB.top">
        <span>
            <!-- delete image button -->
		    <!--<button ion-button small color="dark" icon-right (click)="deleteCanvasItemH();">Delete<ion-icon name="trash"></ion-icon></button>
            <button ion-button small color="primary" icon-right (click)="boardItemSettingsH()">Text & Settings<ion-icon name="settings"></ion-icon></button>-->
            <button ion-button small color="dark" (click)="bringToFront();">Bring To Front</button>
            <button ion-button small color="dark" (click)="deleteCanvasItemH();">Delete</button>
            <button ion-button small color="primary" (click)="boardItemSettingsH()">Text & Settings</button>
            <button ion-button small color="dark" (click)="unselectH()">Un-select</button>
        </span>
    </p>
    <!-- end canvas action buttons -->
</div>