<ion-header>
  <ion-navbar hideBackButton="true" transparent align-title="center">
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
  </ion-navbar>
</ion-header>


<ion-content scrollY="true" class="dp-content">
  <span class="appversion">v{{version}}</span>
  <br>
  <br>
  <br>
  <loginsocial [showIcon]="true" [showLogo]="false"></loginsocial>
</ion-content>
