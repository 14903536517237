<ion-grid id="introstep2c">
  <ion-row>
    <ion-col>
      <ion-range disabled="true" min="commitment??.min" max="commitment.max" step="commitment?.step" snaps="true" [(ngModel)]="commitment.score"
        color="commitment?.color">
        <ion-icon range-left small color="positive" [name]="commitment?.iconMin"></ion-icon>
        <ion-icon class="dp-large-icon dp-neon-glow" range-right color="positive" [name]="commitment?.iconMax"></ion-icon>
      </ion-range>
    </ion-col>
  </ion-row>
  <ion-row class="text-center">
    <ion-col class="dp-dash-grid-box">
      <div class="dp-dash-label">Board</div>
      <div class="dp-dash-value">Plays</div>
    </ion-col>
    <ion-col class="dp-dash-grid-box">
      <div class="dp-dash-label">Week</div>
      <div class="dp-dash-value">{{boardViews?.thisWeek}}</div>
    </ion-col>
    <ion-col class="dp-dash-grid-box">
      <div class="dp-dash-label">Month</div>
      <div class="dp-dash-value">{{boardViews?.thisMonth}}</div>
    </ion-col>
    <ion-col class="dp-dash-grid-box">
      <div class="dp-dash-label">Year</div>
      <div class="dp-dash-value">{{boardViews?.thisYear}}</div>
    </ion-col>
  </ion-row>
</ion-grid>