<ion-header>
	<ion-navbar transparent align-title="center">
		<button id="introstep1c" ion-button menuToggle><ion-icon name="menu"></ion-icon></button>
		<ion-title class="text-center">
			<img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
		</ion-title>
		<ion-buttons end>
			<button class="dp-tablet" id="introstep1a" (click)="startTour(false)" ion-button outline icon-right color="royal">Tour</button>
			<button id="introstep1b" (click)="addNewItem()" ion-button outline icon-right color="royal">New</button>
		</ion-buttons>
	</ion-navbar>
</ion-header>


<ion-content class="dp-page page-homepage">
	<div class="dp-page-wrapper">
		<!-- hidden canvas for temporarily inflating default boards to save -->
		<canvas #fabricCanvasRef id="domBoardID" class="board-canvas hidden">test</canvas>

		<ion-grid>
			<ion-row>
				<!-- begin sample and user boards -->
				<ion-col col-12 col-md-6>
					<!-- add new board -->
					<div>

						<!-- begin latest board -->
						<div id="introstep2a" class="introstep2a" *ngIf="userBoardCount > 0">
							<h2 class="text-center">My Featured Board</h2>
							<board-listcard (click)="itemTapped($event, userBoards[0])" [board]="userBoards[0]?.payload" [properties]="userBoards[0]?.properties">Test</board-listcard>
						</div>
						<!-- end latest board -->

						<br>
						<!-- begin list of user boards -->
						<ion-card *ngIf="userBoardCount > 1" class="dp-stickynote">
							<ion-item no-lines ion-item>
								<div *ngIf="userBoardCount > 0">
									<h1 class="text-center">My Other Boards</h1>
								</div>
								<span *ngIf="userBoardCount > 1">You have created {{userBoardCount}} boards so far</span>
								<span *ngIf="userBoardCount == 1">You have created {{userBoardCount}} board so far</span>
							</ion-item>
							<ion-item *ngIf="userBoardCount == 0" no-lines ion-item>
								<a class="dp-text-shadow-deep" (click)="createFirstBoard()" href="#">Click here to create your first board</a>
							</ion-item>
							<ion-item *ngIf="userBoardCount != 0" no-lines ion-item>
								<ion-list>
									<ion-item no-lines ion-item *ngFor="let item of userBoards">
										<ion-avatar item-left (click)="itemTapped($event, item)">
											<div *ngIf="item && item.payload && item.payload.objects && item.payload.objects.length > 0" class="dp-cont" [ngStyle]="{ 'background-image': 'url(' + item.payload.objects[0].objects[0].src + ')'}"></div>
										</ion-avatar>
										<div *ngIf="item && item.properties">
											<h2 text-wrap (click)="itemTapped($event, item)">{{item.properties.title}}</h2>
											<h3 text-wrap (click)="itemTapped($event, item)">{{item.properties.descrition}}</h3>
											<p text-wrap (click)="itemTapped($event, item)"><span class="dp-list-item-label">Created: &nbsp;</span>{{item.properties.dateTimeCreated | date:"MM/dd/yy hh:mm"}}</p>
										</div>
										<button ion-button clear item-right (click)="viewItem(item)">View</button>
									</ion-item>
								</ion-list>
							</ion-item>
						</ion-card>
						<!-- end list of user boards -->
					</div>
					<div id="introstep2b">
						<!-- begin list of sample boards -->
						<h2 class="text-center">My Sample Boards</h2>
						<ion-card *ngIf="sampleBoardCount > 0" class="dp-stickynote">
							<p no-lines ion-item>
								<!--<div *ngIf="sampleBoardCount > 0">
								<h1 class="text-center">Sample Boards</h1>
							</div>-->
								<span *ngIf="sampleBoardCount > 1">You have {{sampleBoardCount}} sample boards. Edit and save as your board. <br> Simply click and select EDIT in the popup menu</span>
								<span *ngIf="sampleBoardCount == 1">You have {{sampleBoardCount}} sample board </span>
							</p>
							<ion-item *ngIf="sampleBoardCount != 0" no-lines ion-item>
								<ion-list>
									<ion-item no-lines ion-item *ngFor="let item of sampleBoards">
										<ion-avatar item-left (click)="itemTapped($event, item)">
											<div *ngIf="item && item.payload && item.payload.objects && item.payload.objects.length > 0 && item.payload.objects[0].objects"
											 class="dp-cont" [ngStyle]="{ 'background-image': 'url(' + item.payload.objects[0].objects[0].src + ')'}"></div>
										</ion-avatar>
										<div *ngIf="item && item.properties">
											<h2 text-wrap (click)="itemTapped($event, item)">{{item.properties.title}}</h2>
											<h3 text-wrap (click)="itemTapped($event, item)">{{item.properties.descrition}}</h3>
											<p text-wrap (click)="itemTapped($event, item)">
												<span class="dp-list-item-label">Last Used: &nbsp;</span> {{item.properties.dateTimeUsed | date:"MM/dd/yy hh:mm"}}
												&nbsp;&nbsp;&nbsp;
												<!--<span>Created: &nbsp;</span> {{item.properties.dateTimeCreated | date:"MM/dd/yy hh:mm"}}-->
											</p>
										</div>
										<button ion-button clear item-right (click)="viewItem(item)">View</button>
									</ion-item>
								</ion-list>
							</ion-item>
						</ion-card>
						<!-- end list of sample boards -->
					</div>

					<!-- end dp-flexcontainer -->
				</ion-col>
				<!-- end sample and user boards -->

				<!-- begin branding -->
				<ion-col col-12 col-md-6>
					<div>
						<h2 class="text-center">Views</h2>
						<ion-row>
							<ion-col class="dp-dash-grid-box">
								<div class="dp-dash-label">This week</div>
								<div class="dp-dash-value">{{boardViews.thisWeek}}</div>
							</ion-col>
							<ion-col class="dp-dash-grid-box">
								<div class="dp-dash-label">This month</div>
								<div class="dp-dash-value">{{boardViews.thisMonth}}</div>
							</ion-col>
							<ion-col class="dp-dash-grid-box">
								<div class="dp-dash-label">This year</div>
								<div class="dp-dash-value">{{boardViews.thisYear}}</div>
							</ion-col>
						</ion-row>
					</div>
					<br>
					<h2 class="text-center">Commitment</h2>
					<ion-list id="introstep2c">
						<ion-item>
							<h2 class="text-center">Commitment</h2>
							<ion-range disabled="true" min="commitment.min" max="commitment.max" step="commitment.step" snaps="true" [(ngModel)]="commitment.score"
							 color="commitment.color">
								<ion-icon range-left small color="positive" [name]="commitment.iconMin"></ion-icon>
								<ion-icon class="dp-large-icon dp-neon-glow" range-right color="positive" [name]="commitment.iconMax"></ion-icon>
							</ion-range>
						</ion-item>
					</ion-list>
					<!--<div>
					<ion-row>
						<ion-col class="dp-dash-grid-box">
							<div class="dp-dash-label">Last Board Viewed</div>
							<div class="dp-dash-label">{{boardViews.lastUsedDate| date:"MM/dd/yy &nbsp;&nbsp; hh:mm"}}</div>
						</ion-col>
						<ion-col class="dp-dash-grid-box">
							<div class="dp-dash-label">Last Board Modified</div>
							<div class="dp-dash-label">{{boardViews.lastModifiedDate| date:"MM/dd/yy &nbsp;&nbsp; hh:mm"}}</div>
						</ion-col>
					</ion-row>
				</div>-->
					<br>
					<br>
					<div>
						<div class="mb-wrap mb-style-3">
							<blockquote>
								<p>{{quote.text}}</p>
							</blockquote>
							<div class="mb-attribution">
								<p class="mb-author">{{quote.author}}</p>
							</div>
						</div>
					</div>

					<!-- begin quote component -->
					<div id="introstep2c">
						<quotelist></quotelist>
					</div>
				</ion-col>
				<!-- end branding -->

			</ion-row>
		</ion-grid>
	</div>
</ion-content>