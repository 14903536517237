<ion-header>
  <ion-navbar>
    <button menuToggle>
			<ion-icon name="menu"></ion-icon>
		</button>
    <ion-title>{{payload.yt.snippet.title}}</ion-title>
    <!--<ion-buttons end>
      <button (tap)="shareVideo()" ion-button icon-only>
            <ion-icon name="share"></ion-icon>
          </button>
    </ion-buttons>-->
  </ion-navbar>
</ion-header>

<ion-content class="youtube-video">
  <iframe *ngIf="payload.yt.snippet.resourceId.videoId" [src]="videoUrl" frameborder="0" allowfullscreen="true"></iframe>

  <div padding>
    <h2>{{payload.yt.snippet.title}}</h2>
    <p>Published by <strong>{{payload.yt.snippet.channelTitle}}</strong></p>
    <p [innerHtml]="payload.yt.snippet.description"></p>
  </div>
</ion-content>