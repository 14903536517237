<ion-header>
  <ion-navbar transparent align-title="center">
    <button id="introstep1c" ion-button menuToggle>
      <ion-icon name="menu"></ion-icon>
    </button>
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
    <ion-buttons end>
      <!--<button class="dp-tablet" (click)="testFeature()" ion-button outline icon-right color="royal">Test</button>-->
      <!--<button class="dp-tablet" id="introstep1a" (click)="startTour(false)" ion-button outline icon-right color="royal">Tour</button>
      <button id="introstep1b" (click)="addNewItem()" ion-button outline icon-right color="royal">New</button>
      <button class="dp-tablet" (click)="showLoginModalH()" ion-button outline icon-right color="royal">Login</button>-->
      <button type="button" class="dp-tablet" id="introstep1a" (click)="contactH()" ion-button outline icon-right color="royal">Support</button>
      <button type="button" class="dp-tablet" id="introstep1a" (click)="logoutH()" ion-button outline icon-right color="royal">Logout</button>
    </ion-buttons>
  </ion-navbar>
</ion-header>
<ion-content padding>
  <ion-grid class="grid-full">
    <ion-row justify-content-center align-items-center class="grid-row-80h">
      <ion-col justify-content-center align-items-center class="flex-container">
        <div class="cont-board">
          <div class="text-center">
            <span>{{activeBoardtitle}}</span> &nbsp;
            <span class="small-text">(Hint: Click board for options)</span>
          </div>
          <board-classic (click)="itemTapped()" isFullScreen="no" [showBoardTimer]="showBoardTimer" [boardWidth]="mobileBoardWidth"
            [boardHeight]="mobileBoardHeight" [domBoardID]='domBoardID'>
          </board-classic>
          <!-- june 2018 remove nag cta
          <div *ngIf="(plan|async); let plan">
            <div *ngIf="plan?.planID == 'bigdreamboardtrial017'" class="text-center" (click)="ctaPromptClickH()">
              <span class="cta-prompt">{{ctaPrompt1}}.</span>
              <span class="cta-prompt highlight">{{ctaPrompt2}}</span>
            </div>
          </div>
          end june 2018 remove nag cta-->
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <p class="dp-multifab-cont">
          <ion-fab center bottom>
            <ion-grid>
              <ion-row>
                <ion-col padding>
                  <button (click)="toggleBottomToolPaneH()" ion-fab mini color="dark">
                    <ion-icon name="apps"></ion-icon>
                  </button>
                </ion-col>
                <ion-col padding>
                  <button (click)="editItem()" ion-fab mini color="dark">
                    <ion-icon name="create"></ion-icon>
                  </button>
                </ion-col>
                <ion-col padding>
                  <button (click)="viewItem()" ion-fab mini color="purple">
                    <ion-icon name="play"></ion-icon>
                  </button>
                </ion-col>
                <ion-col padding>
                  <button (click)="toggleAudioToolPaneH()" ion-fab mini color="dark">
                    <ion-icon name="musical-notes"></ion-icon>
                  </button>
                </ion-col>
                <!--<ion-col padding>
                  <button (click)="contactH()" ion-fab mini color="dark">
                    <ion-icon name="help-circle"></ion-icon>
                  </button>
                </ion-col>-->
                <!--<ion-col>
                  <button (click)="boardSettingsH()" ion-fab color="dark"><ion-icon name="cog"></ion-icon></button>
                </ion-col>-->
                <ion-col>
                  <ion-fab padding #fab>
                    <button ion-fab mini color="dark">
                      <ion-icon name="md-move"></ion-icon>
                    </button>
                    <ion-fab-list side="bottom">
                      <button ion-fab color="vimeo" (click)="zoomInH()">
                        <ion-icon name="add-circle"></ion-icon>
                      </button>
                      <button ion-fab color="vimeo" (click)="zoomOutH()">
                        <ion-icon name="remove-circle"></ion-icon>
                      </button>
                    </ion-fab-list>
                  </ion-fab>
                </ion-col>

              </ion-row>
            </ion-grid>
          </ion-fab>
          <ion-fab bottom right #fab class="dp-tablet">
            <button ion-fab>
              <ion-icon name="share"></ion-icon>
            </button>
            <ion-fab-list side="top">
              <button shareButton="pinterest" ion-fab color="vimeo" (click)="openSocial('Vimeo', fab)">
                <ion-icon name="logo-pinterest"></ion-icon>
              </button>
              <!--<button ion-fab color="google" (click)="openSocial('Google+', fab)"><ion-icon name="logo-googleplus"></ion-icon></button>-->
              <button shareButton="twitter" ion-fab color="twitter" (click)="openSocial('Twitter', fab)">
                <ion-icon name="logo-twitter"></ion-icon>
              </button>
              <button shareButton="facebook" ion-fab color="facebook" (click)="openSocial('Facebook', fab)">
                <ion-icon name="logo-facebook"></ion-icon>
              </button>
            </ion-fab-list>
          </ion-fab>
        </p>
        <br>
        <div *ngIf="showAudioToolPane" justify-content-center align-items-center class="tool-pane">
          <div class="dp-pane-title text-center">
            <span>Use the same button to play / pause board music</span>&nbsp;
            <span class="small-text">(Hint: You can change sound track under board settings)</span>
          </div>
          <audio-track #track [track]="singleTrack">
            <ion-item [audioTrack]="track">
              <ion-thumbnail item-left>
                <img src="{{track.art}}">
                <audio-track-play light [audioTrack]="track">
                  <ion-spinner></ion-spinner>
                </audio-track-play>
              </ion-thumbnail>

              <h2>{{track.title}}</h2>
              <p>
                <audio-track-progress [audioTrack]="track"></audio-track-progress>
              </p>
            </ion-item>
          </audio-track>
        </div>
      </ion-col>
    </ion-row>
    <!-- begin tool pane -->
    <!--<ion-row justify-content-center align-items-center class="grid-row-20h tool-pane box-shadow">-->
    <ion-row *ngIf="showBottomToolPane" justify-content-center align-items-center class="tool-pane">
      <ion-col class="tool-pane-col pane-boards" col-12 col-md-4>
        <h5 class="toolpane-title-text">Vision Boards</h5>
        <ion-list>
          <ion-item>
            <ion-label>My Boards</ion-label>
            <ion-select name="selecteduserboard" (ionChange)="selecteduserboardH()" [(ngModel)]="selecteduserboard" required>
              <!--<ion-option value="">Select Board</ion-option>-->
              <!--<ion-option value="new">+ Create New</ion-option>-->
              <ion-option *ngFor="let item of userBoards" [value]="item">{{item?.properties?.title}}</ion-option>
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-label>Sample Boards</ion-label>
            <ion-select name="selectedsampleboard" (ionChange)="selectedsampleboardH()" [(ngModel)]="selectedsampleboard" required>
              <!--<ion-option value="">Select Board</ion-option>-->
              <ion-option *ngFor="let item of sampleBoards" [value]="item">{{item?.properties?.title}}</ion-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col class="tool-pane-col  pane-commitment" col-12 col-md-4>
        <h5 class="toolpane-title-text">Commitment</h5>
        <commitmentcompact [boardStats]="boardViews" [commitmentStats]="commitment"></commitmentcompact>
      </ion-col>
      <!-- quotes and affirmations -->
      <ion-col class="tool-pane-col pane-quotes" col-12 col-md-4>
        <h5 class="toolpane-title-text">Quotes & Affirmations</h5>
        <p class="text-center">
          <button ion-button icon-right color="purple" (click)="showQuotesModal()">List
            <ion-icon name="list"></ion-icon>
          </button>
          <button ion-button icon-right color="purple" (click)="showQOTD()">Inspire Me
            <ion-icon name="flash"></ion-icon>
          </button>
        </p>
      </ion-col>
    </ion-row>
    <!-- begin tool pane -->
  </ion-grid>

</ion-content>