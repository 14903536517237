<div id="pricing-table" class="clear">
  <ion-row wrap class="dp-wrap">
    <ion-col class="plan" id="most-popular">
      <h3 class="none">Monthly
        <br> Plan
        <span>$8</span>
      </h3>
      <p>Free 30-day trial. Pay nothing when you cancel within 30 days</p>
      <br>
      <p>$8 per month billed monthly after trial period</p>
      <subscription-payment [planID]="plans?.monthly.planID" [amount]="plans?.monthly.amount" [planName]="plans?.monthly.name"
        [planDescription]="plans?.monthly.description" [buttonText]="plans?.monthly.buttonText"></subscription-payment>
      <ul>
        <li>
          <b>5GB</b> disk space for photos & videos</li>
        <li>
          <b>Unlimited</b> vision boards</li>
        <li>
          <b>10%</b> donated to
          <a [href]="charityLink">Child Sight (HKI)</a>
        </li>
      </ul>
    </ion-col>
    <ion-col class="plan">
      <h3 class="gold">Lifetime
        <br> Membership
        <span>$120</span>
      </h3>
      <p>Free 30-day trial. Pay nothing when you cancel within 30 days</p>
      <br>
      <p>$120 billed once after trial period.
        <br>
        <span class="dp-large-text">No more payments. Ever</span>
      </p>
      <subscription-payment [planID]="plans?.lifetime.planID" [amount]="plans?.lifetime.amount" [planName]="plans?.lifetime.name"
        [planDescription]="plans?.lifetime.description" [buttonText]="plans?.lifetime.buttonText"></subscription-payment>
      <ul>
        <li>
          <b>5GB</b> disk space for photos & videos</li>
        <li>
          <b>Unlimited</b> vision boards</li>
        <li>
          <b>30%</b> donated to
          <a [href]="charityLink">Child Sight (HKI)</a>
        </li>
      </ul>
    </ion-col>
    <ion-col class="plan">
      <h3 class="none">Annual
        <br> Plan
        <span>$60</span>
      </h3>
      <p>Free 30-day trial. Pay nothing when you cancel within 30 days</p>
      <br>
      <p>$60 per year billed once annually. Save 15% over monthly plan.</p>
      <subscription-payment [planID]="plans?.annual.planID" [amount]="plans?.annual.amount" [planName]="plans?.annual.name" [planDescription]="plans?.annual.description"
        [buttonText]="plans?.annual.buttonText"></subscription-payment>
      <ul>
        <li>
          <b>5GB</b> disk space for photos & videos</li>
        <li>
          <b>Unlimited</b> vision boards</li>
        <li>
          <b>20%</b> donated to
          <a [href]="charityLink">Child Sight (HKI)</a>
        </li>
      </ul>
    </ion-col>

  </ion-row>
</div>
