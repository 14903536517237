<ion-header>
  <ion-navbar transparent align-title="center">
    <button id="introstep1c" ion-button menuToggle>
      <ion-icon name="menu"></ion-icon>
    </button>
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
    <ion-buttons end>
      <!--<button class="dp-tablet" id="introstep1a" (click)="startTour(false)" ion-button outline icon-right color="royal">Tour</button>
      <button class="dp-tablet" (click)="logoutH()" ion-button outline icon-right color="royal">Logout</button>-->
      <button class="dp-tablet" (click)="goToHome()" ion-button outline icon-right color="royal">Home</button>
    </ion-buttons>
  </ion-navbar>
</ion-header>
<ion-content padding>
  <ion-grid class="grid-full">
    <div>
      <h5 class="dp-phone">Note: For best results create your boards on larger screen devices such as tablets and computers and not your phone</h5>
    </div>
    <ion-row>
      <!-- begin section 1b - add photos section -->
      <horizpicseltr ion-col (thumbnailClickEvt)="onThumbnailClickPhone($event)">
      </horizpicseltr>
      <!-- end add photos section -->
    </ion-row>
    <ion-row justify-content-center align-items-center class="grid-row-70h">
      <ion-col justify-content-center align-items-center class="flex-container">
        <div class="cont-board">
          <div class="text-center">
            <span>{{activeBoardtitle}}</span> &nbsp;
            <span class="small-text">(Hint: You can drag photos from your computer onto the board)</span>
          </div>
          <board-classic #board isFullScreen="no" [showBoardTimer]="showBoardTimer" [domBoardID]='domBoardID' (onEditActiveBoardItem)="boardItemSettingsH($event)"></board-classic>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <!-- begin section 3 - save & exit -->
      <div ion-col class="widgets-cont2">
        <div class="dp-text-cont">
          <h6 class="text-center">Step 2 - Save & Exit</h6>
        </div>
        <p class="text-center">
          <button ion-button small color="purple" icon-right #boardSettingsButton (click)="boardSettingsH(board)">Board Settings
            <ion-icon name="settings"></ion-icon>
          </button>
          <button ion-button small icon-right (click)="saveBoardAndExit(domBoardID)">Save & Exit
            <ion-icon name="checkmark-circle"></ion-icon>
          </button>
          <button ion-button small color="purple" icon-right (click)="goToHome()">Cancel / Home
            <ion-icon name="home"></ion-icon>
          </button>
        </p>
      </div>
      <!-- end section 3 - save & exit -->
    </ion-row>

  </ion-grid>
</ion-content>
