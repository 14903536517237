<ion-header>
  <ion-navbar hideBackButton="true">
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
  </ion-navbar>
</ion-header>

<ion-content scrollY="true" class="dp-content">
  <br><br><br>
  <div class="form-container">
    <img *ngIf="shouldShowLogo" alt="Big Dream Board Logo" [src]="logoSrc">
    <ion-icon *ngIf="shouldShowIcon" class="intro-icon dp-theme-primary" name="checkmark-circle-outline"></ion-icon>

    <!--<span class="error" *ngIf="loginerror">{{ loginerror }}</span>-->
    <br><br>
    <div>
      <form [formGroup]="resetPasswordForm" (submit)="resetPassword()" novalidate class="form-horizontal">
        <!-- email -->
        <ion-item>
          <ion-label stacked>{{config.loginfrmEmailLbl}}</ion-label>
          <ion-input #email formControlName="email" type="email" placeholder="Your email address" [class.invalid]="!resetPasswordForm.controls.email.valid && (emailChanged || submitAttempt)"></ion-input>
        </ion-item>
        <ion-item class="error-message" *ngIf="!resetPasswordForm.controls.email.valid  && (emailChanged || submitAttempt)">
          <p>{{config.loginfrmEmailErr}}</p>
        </ion-item>

        <!-- buttons -->
        <p class="text-center">
          <button ion-button [disabled]="!resetPasswordForm.valid" type="submit">Send Reset Password Email</button>
        </p>
        <a href="javascript:void(0)" (click)="goToSignup()" class="alc ">No account? <strong>Create one here</strong></a>
      </form>
    </div>
    <br><br>
    <div>
      <button type="button" (click)="login('gg')" id="google">Login With Google</button>
      <button type="button" (click)="login('fb')" id="fb">Login With Facebook</button>
    </div>
  </div>
</ion-content>