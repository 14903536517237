<table>
  <!--<thead>
    <tr>
      <th class="dp-tbl-header">Select</th>
      <th class="dp-tbl-header"> Listen To Sample</th>
    </tr>
  </thead>-->
  <tbody>
    <!--<tr radio-group class="dp-tbl-row">
      <td class="dp-tbl-col text-center">
        <ion-radio [checked]="noMusicSelected" [value]="-1" (click)="audoiListSelectorClickH($event,-1)"></ion-radio>
      </td>
      <td class="dp-tbl-col">
        <div class="text-center">No music</div>
      </td>
    </tr>-->

    <tr radio-group class="dp-tbl-row" *ngFor="let track of myTracks;index as i">
      <td class="dp-tbl-col text-center">
        <ion-radio [checked]="track.selected" [value]="track.idx" (click)="audoiListSelectorClickH($event,i)"></ion-radio>
      </td>
      <!-- for no music -->
      <td *ngIf="i == 0" class="dp-tbl-col">
        <div class="text-center">No music {{track.selected}}</div>
      </td>

      <td *ngIf="i != 0" class="dp-tbl-col">
        <audio-track #audio [track]="track" (onFinish)="onTrackFinished($event)">
          <ion-item class="dp-noborder">
            <ion-thumbnail item-left>
              <img src="{{audio.art}}">
              <audio-track-play primary [audioTrack]="audio">
                <ion-spinner></ion-spinner>
              </audio-track-play>
            </ion-thumbnail>
            <div item-content style="width:100%">
              <p><strong class="dp-theme-primary">{{audio.title}}</strong> &#x26AC; By &#x26AC; <em>{{audio.artist}}</em></p>
              <audio-track-progress-bar duration progress [audioTrack]="audio"></audio-track-progress-bar>
              <!--<em style="font-size:.5em">Track ID: {{audio.id}}</em>-->
            </div>
          </ion-item>
        </audio-track>
      </td>
    </tr>
  </tbody>
</table>