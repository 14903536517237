<ion-header>
  <ion-navbar transparent align-title="center">
    <button ion-button menuToggle><ion-icon name="menu"></ion-icon></button>
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
  </ion-navbar>
</ion-header>

<ion-content class="dp-page helpPage" padding>
  <div padding>
    <!--<h1 class="text-center"> Quick Start Guide</h1>-->
    <!-- Intro -->
    <p>Big Vision Board allows you to quickly create your vision board in 3 easy steps. Follow the instructions below to create
      and start using your board.
    </p>

    <!-- Creation -->
    <h2>How to Create Your Board</h2>
    <p>To create a board, you can use the included images or your own images. For best results use images that are most meaningful
      to you. Big Vision Board allows you to use a variety of image formats including .gif, .jpg and .png. You can find images
      from a variety of sources including web searches as well as the following sites:

    </p>

    <h4>
      Assemble the images that you would like to use and follow the steps below:
    </h4>

    <ul>
      <li>Login</li>
      <li>Using the hamburger menu in the upper right-hand corner, open the side menu</li>
      <li>Select <span class="help-command">Create</span> in the side menu. Doing this will take you to the Create view</li>
      <li>Click on the <span class="help-command">Step 1 - Photos</span> button in the right-hand side tool strip to reveal a
        linear gallery of image thumbnails</li>
      <li>Scroll through the thumbnails and select the desired image. Doing so places it on the board automatically in a suitable
        location. Click on the image on the board and move it to a new location within the board if needed</li>
    </ul>

    <h4>Using your own images:</h4>
    <ul>
      <li>Click on the <span class="help-command">Step 1 - Photos</span> button in the right-hand side tool strip to reveal a
        linear gallery of image thumbnails</li>
      <li>Click on the <span class="help-command">Upload Images</span> button at the bottom of the thumbnail gallery</li>
      <li>In the file selection dialog that pops up navigate to images saved on your computer and select them to add them to
        the board
      </li>
      <li>Another way to add an image to the board is to simply drag and drop it from your computer on the surface of the board</li>
    </ul>

    <h4>Managing images on your board:</h4>
    <ul>
      <li>Once an image is placed on the board, you can click and select it, move it around, resize and rotate it and also delete
        it from the board</li>
      <li>Deleting an image from the board will not delete it from your gallery</li>
      <li>Since images will be shown 1 at a time when you view your board, you do not have to precisely arrange the images on
        the board and overlapping images are ok</li>
      <li>When all images have been placed on the board hit the <span class="help-command">Step 2 - Save</span> button to save
        your board and board creation is complete. You have now successfully created a board</li>
    </ul>

    <!-- Visualization -->
    <h2>Visualization</h2>
    <p>The primary aim of Big Vision Board is to make you feel like you have the thing or situation your desire now, not in
      future, now. Having a deep unwavering conviction that you already have the results you seek is what sets events in
      motion and moves you towards achiving. This is why all the great teachers advocate adding movement, sounds, smells
      and vivid detailed pictures when you visualize. Big Vision Board allows you to play back your board like a movie with
      movement added even for still photos. To play back your board follow the steps below:
    </p>

    <ul>
      <li>Open the application</li>
      <li>If click or tap on one of the boards shown on the home screen a popup menu will be shown. Click on the <span class="help-command">Play</span>        menu item</li>
      <li>Big Vision Board will begin playback of your board allowing you to spend an equal amount of time on each item</li>
      <!--<li>To replay the board, click or tap on the replay button at the top of the board</li>-->
      <li>To interupt playback and cut your session short, click on the <span class="help-command">Home</span> button in the
        upper right-hand corner</li>
      <li>When play back is complete or when you click the <span class="help-command">Home</span> you will be returned to the
        home screen</li>
    </ul>
  </div>
</ion-content>