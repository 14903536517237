<!--
<ion-list>
  <ion-grid fixed>
    <ion-row align-items-stretch>
      <ion-col col-12 col-sm-6 col-md-2 align-self-stretch align-self-center *ngFor="let itm of resources" approxItemHeight="457px">
        <booklistitem [book]="itm" (click)="loadBook(itm)"></booklistitem>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-list> -->

<ion-grid>
  <ion-row>
    <ion-col col-2>
    </ion-col>
    <ion-col col-8>
      <div class="resource-list-intro text-center">Here are a list of books we have found to be useful and inspirational. Special thanks each of the authors for writting
        these life-changing books.</div>
    </ion-col>
    <ion-col col-2>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col col-2>
    </ion-col>
    <ion-col col-8>
      <ion-list>
        <div *ngFor="let itm of resources" approxItemHeight="457px">
          <!--<a [href]="itm.book.canonicalVolumeLink"></a>-->
          <booklistitem [book]="itm" (click)="loadBook(itm)"></booklistitem>
        </div>
      </ion-list>
    </ion-col>
    <ion-col col-2>
    </ion-col>
  </ion-row>
</ion-grid>