<!--<ion-header>
  <ion-navbar transparent align-title="center">
  </ion-navbar>
</ion-header>-->

<ion-content padding>
  <h1 class="text-center">Welcome</h1>
  <h4 class="text-center">Big Dream Board Tour</h4>
  <br>
  <ion-slides pager="true">
    <ion-slide>
      <ion-row>
        <ion-col>
          <p class="text-center">
            <button ion-button icon-end color="dark" (click)="goToSlide(1)">Next
              <ion-icon name="arrow-forward"></ion-icon>
            </button>
          </p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <img src="assets/img/help/vision_board_creator_help-home-clean.png" />
        </ion-col>
      </ion-row>
    </ion-slide>

    <ion-slide>
      <ion-row>
        <ion-col>
          <p class="text-center">
            <button ion-button icon-start color="dark" (click)="goToSlide(0)">
              <ion-icon name="arrow-back"></ion-icon>Previous </button>
            <button ion-button icon-start color="light" (click)="goToHome()">
              <ion-icon name="apps"></ion-icon> Take me to the app</button>
            <button ion-button icon-end color="dark" (click)="goToSlide(2)">Next
              <ion-icon name="arrow-forward"></ion-icon>
            </button>
          </p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <img src="assets/img/help/vision_board_creator_help-home-toolpane.png" />
        </ion-col>
      </ion-row>
    </ion-slide>

    <ion-slide>
      <ion-row>
        <ion-col>
          <p class="text-center">
            <button ion-button icon-start color="dark" (click)="goToSlide(1)">
              <ion-icon name="arrow-back"></ion-icon>Previous </button>
            <button ion-button icon-start color="light" (click)="goToHome()">
              <ion-icon name="apps"></ion-icon> Take me to the app</button>
          </p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <img src="assets/img/help/vision_board_creator_help-edit.png" />
        </ion-col>
      </ion-row>
    </ion-slide>
  </ion-slides>
</ion-content>
