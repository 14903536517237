<div>
  <div class="tool-strip-vertical">
    <div id="{{itm.introjsid}}" (click)="toolBtnClickH(itm);" class="dp-vertical-button-cont {{itm.active ? 'active': 'inactive'}}"
      *ngFor="let itm of tools">

      <button ion-button class="dp-tablet button-clear">
        <ion-icon name="{{itm.icon}}"></ion-icon>
      </button>

      <button *ngIf="itm.labelphone != 'NA'" ion-button class="dp-phone button-clear">
        <ion-icon name="{{itm.icon}}"></ion-icon>
      </button>

      <div class="dp-tablet">{{itm.label}}</div>
      <div *ngIf="itm.labelphone != 'NA'" class="dp-phone">{{itm.labelphone}}</div>
    </div>
  </div>
</div>