<ion-header>
  <ion-navbar hideBackButton="true" transparent align-title="center">
    <ion-title class="text-center">
      <img class="dp-logo-small text-center" alt="Big Dream Board Logo" [src]="logoSrc">
    </ion-title>
  </ion-navbar>
</ion-header>

<ion-content scrollY="true" class="dp-content">
  <br>
  <br>
  <br>
  <div class="form-container">
    <img *ngIf="shouldShowLogo" alt="Big Dream Board Logo" [src]="logoSrc">
    <ion-icon *ngIf="shouldShowIcon" class="intro-icon dp-theme-primary" name="checkmark-circle-outline"></ion-icon>
    <a href="javascript:void(0)" (click)="goToLogin()" class="alc ">Already have account?
      <strong>Login in here</strong>
    </a>
    <!--<span class="error" *ngIf="loginerror">{{ loginerror }}</span>-->
    <!-- <button type="button" (click)="login('email')" id="email">Login With Email</button>-->
    <div *ngIf="loginMode == 'email' ">
      <h2 class="text-center"> Sign Up </h2>
      <div class="dp-error text-center">{{error}}</div>
      <form [formGroup]="loginForm" (submit)="register($event,email.value,password.value,passwordconfirm.value)" novalidate class="form-horizontal">
        <!-- email -->
        <ion-item>
          <ion-label stacked>{{config.loginfrmEmailLbl}}</ion-label>
          <ion-input #email formControlName="email" type="email" placeholder="Your email address" [class.invalid]="!loginForm.controls.email.valid && (emailChanged || submitAttempt)"></ion-input>
        </ion-item>
        <ion-item class="error-message" *ngIf="!loginForm.controls.email.valid  && (emailChanged || submitAttempt)">
          <p>{{config.loginfrmEmailErr}}</p>
        </ion-item>
        <!-- password -->
        <ion-item>
          <ion-label stacked>{{config.loginfrmPwdLbl}}</ion-label>
          <ion-input #password formControlName="password" type="password" (change)="elementChanged(password)" placeholder="Your password"
            [class.invalid]="!loginForm.controls.password.valid && (passwordChanged || submitAttempt)"></ion-input>
        </ion-item>
        <ion-item class="error-message" *ngIf="!loginForm.controls.password.valid  && (passwordChanged || submitAttempt)">
          <p>{{config.loginfrmPwdErr}}</p>
        </ion-item>
        <!-- confirm password -->
        <ion-item>
          <ion-label stacked>{{config.loginfrmConfirmPwdLbl}}</ion-label>
          <ion-input #passwordconfirm formControlName="passwordconfirm" type="password" (change)="elementChanged(passwordconfirm)"
            placeholder="Confirm password" [class.invalid]="!loginForm.controls.passwordconfirm.valid && (passwordconfirmChanged || submitAttempt)"></ion-input>
        </ion-item>
        <ion-item class="error-message" *ngIf="!loginForm.controls.password.valid  && (passwordChanged || submitAttempt)">
          <p>{{config.loginfrmPwdErr}}</p>
        </ion-item>
        <!-- buttons -->
        <p class="text-center">
          <button ion-button [disabled]="!loginForm.valid" type="submit">{{config.loginBtn}}</button>
          <!--<button ion-button type="button" outline (click)="goToResetPassword()">{{config.loginBtnForgot}} </button>-->
          <!-- <button ion-button type="button" outline (click)="cancelEmailLogin()">{{config.loginBtnCancel}} </button>-->
        </p>
      </form>
    </div>
    <div>
      <h2 class="text-center">OR</h2>
      <button type="button" (click)="login('gg')" id="google">Login With Google</button>
      <button type="button" (click)="login('fb')" id="fb">Login With Facebook</button>
    </div>
  </div>
</ion-content>
