<ion-grid class="grid-full">
  <ion-row>
    <ion-col>
      <ion-searchbar [(ngModel)]="searchTerm" [formControl]="searchControl" (ionInput)="onSearchInput()"></ion-searchbar>
    </ion-col>
    <ion-col col-auto>
      <button ion-button icon-right (click)="showQOTD()" class="searchbar-button">Inspire Me<ion-icon name="flash"></ion-icon></button>
    </ion-col>
  </ion-row>
  <ion-row class="dp-full">
    <ion-col col-12>
      <div *ngIf="searching || loading" class="spinner-container">
        <ion-spinner></ion-spinner>
      </div>
      <ion-scroll scrollY="true" scrollX="false" class="quotes-cont">
        <ion-list>
          <ion-item text-wrap *ngFor="let item of items">
            <p class="quoteText text-wrap">{{item.quote}}</p>
            <div class="quoteAuthor text-wrap">{{item.author}}</div>
          </ion-item>
        </ion-list>
      </ion-scroll>
    </ion-col>
  </ion-row>
</ion-grid>