
<ion-header>
  <ion-toolbar>
    <ion-title class="text-center">Thanks for your support</ion-title>
    <ion-buttons start>
      <button ion-button (click)="dismiss()">
        <!--<span ion-text color="primary" showWhen="ios">Cancel</span>
        <ion-icon name="md-close" showWhen="android, windows, web"></ion-icon>-->
        <ion-icon name="md-close" ></ion-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content><pricingtable></pricingtable></ion-content>
